module.exports = [{
      plugin: require('../../shared/plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../shared/plugins/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"1d74384b81f24b289fe4563ce3b6a20e","enabled":true,"captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"prod"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Лизинг оборудования для малого бизнеса: взять оборудование в лизинг, условия для юридических лиц и ИП","short_name":"Arenza","description":"Аренза – лизинговая компания для малого бизнеса, юридических лиц и ИП ✅ Взять оборудование в лизинг онлайн из любой точки России ✅ Выгодные условия, минимальные проценты","start_url":"/","lang":"ru","background_color":"#232d3e","theme_color":"#FE5200","display":"standalone","icon":"static/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"98981e52aa332c204b00bb6409e5f290"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KHP2ZTGQ","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
